<template>
  <h2 class="text-2xl font-bold mb-4">Who am I?</h2>
  <div
    class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans"
    typeof="Person"
  >
    <p class="mt-4" itemprop="description">
      An enthusiastic hands-on Solution Architect and a full-stack software
      developer with many years of experience with AWS, Linux, Cloud, Containers,
      etc. I support OSS, and I love
      <font-awesome-icon
        class="text-red-800 text-base mx-1"
        :icon="['fa', 'heart']"
      />
      Linux.
    </p>
    <p class="mt-4">
      During my career, I have built highly scalable APIs, automated quality
      checks and deployment (CI/CD pipelines), and scalable, reliable, and
      secure systems on cloud
      <font-awesome-icon
        class="text-green-600 text-base"
        :icon="['fa', 'cloud']"
      />
      infrastructures, mainly AWS.
      I have worked as a freelancer, consultant, and employee for various
      companies, including small startups, scale-ups, and large enterprise
      corporations. IT security is one of my side habits.
    </p>
    <p class="mt-4">
      No one knows everything, so I am constantly learning from different
      sources and trying new things.
    </p>
    <p class="mt-4">
      Fun facts about me:
      <ul class="list-disc list-inside pt-2">
        <li class="pl-2 pt-2">
          I have worked in data centers!
        </li>
        <li class="pl-2 pt-2">
          I have won a security CTF hackathon at work
        </li>
      </ul>
    </p>
    <p class="mt-4">
      I believe in freedom of speech, equality, diversity, and inclusion, and I
      actively promote them. I think every human should have an equal
      opportunity to learn and be successful, no matter what color, race,
      gender, disability, religion, look, or country we are born in. I believe
      the only thing that can differentiate us is our actions. I also think
      people and every living being should be respected, but we should allow
      thoughts and beliefs to be openly challenged.
      <span class="text-base md:text-sm">🕊️</span>
    </p>
  </div>
  <h2 class="text-xl font-bold mb-5 mt-6">Projects (owned by me)</h2>
  <div class="flex flex-row mb-4 flex-wrap">
    <hobby-project-item
      v-for="project in projects"
      :key="project.name"
      :project="project"
    ></hobby-project-item>
  </div>
  <h2 class="text-xl font-bold mb-4 mt-6">Professional skills</h2>
  <div class="flex flex-row mb-4 flex-wrap">
    <div
      class="flex-initial mx-2 mb-2"
      v-for="skill in skills"
      :key="skill.name"
    >
      <skill-bar-item :skill="skill"></skill-bar-item>
    </div>
  </div>
  <h2 class="text-xl font-bold mb-4 mt-6">Languages</h2>
  <div class="flex flex-row mb-4 flex-wrap">
    <div
      class="flex-initial mx-2 mb-2"
      v-for="language in languages"
      :key="language.name"
    >
      <skill-bar-item :skill="language"></skill-bar-item>
    </div>
  </div>
  <h2 class="text-xl font-bold mb-4 mt-6">Public Talks</h2>
  <div class="grid mb-4 flex-wrap space-x-4 md:grid-cols-2 2xl:grid-cols-3">
    <div
      class="flex-initial mb-2"
      v-for="talk in talks"
      :key="talk.title"
    >
      <a :href="talk.url" target="_blank">
        <img
          class="rounded-md mb-3 shadow-md opacity-80 mx-auto hover:opacity-100 transition-opacity cursor-pointer w-full"
          :alt="talk.title"
          :src="talk.image"
        />
      </a>
      <div class="w-fit flex flex-col justify-center truncate">
        <a class="mx-auto whitespace-nowrap link text-xs font-semibold uppercase" :href="talk.url" target="_blank" v-html="talk.title"></a>
        <span class="mx-auto block text-xs font-semibold uppercase text-gray-700 dark:text-gray-300">{{ talk.event }}</span>
        <span class="mx-auto block text-xs font-semibold uppercase text-gray-700 dark:text-gray-300"> {{ talk.month }} {{ talk.year }}</span>
      </div>
    </div>
  </div>
  <h2 class="text-xl font-bold mb-6 mt-8">What people say about me?</h2>
  <div
    class="mb-8 ml-2 md:ml-4"
    v-for="recommendation in recommendations"
    :key="recommendation.id"
  >
    <recommendation :recommendation="recommendation"></recommendation>
  </div>
  <div
    class="mt-4 mb-8 ml-2 md:ml-4 mx-auto"
    >
      <img class="mx-auto rounded-md" src="/img/linkedin-recommendations.png"  alt="Recommendations on linkedIn"/>
  </div>
  <h2 class="text-2xl font-bold mb-4">Disclaimer</h2>
  <ul class="list-disc list-outside text-sm">
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      While every care has been taken to ensure the accuracy of the content of
      this website, I make no representation as to the accuracy, correctness or
      fitness for any purpose of the site content, nor I accept any liability
      for loss or damage (including consequential loss or damage), however
      caused, which may be incurred by any person or organisation from reliance
      on or use of information on this site.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      The contents of this website should not be construed as legal advice.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      Opinions are my own and not the views of my employer.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      I am not a political expert or a historian. That means, even though I
      might share my views based on the three decades of my personal life under
      the dictatorship in Iran, they might not be 100% accurate.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      English is not my mother-tongue language, so even though I try my best to
      express myself correctly, there might be chance of miscommunication.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      Links or references to other websites, including the use of information
      from 3rd-parties, are provided for the benefit of people who use this
      website. I am not responsible for the accuracy of the content on the
      websites that I have put a link to and I do not endorse any of those
      organizations or their contents.
    </li>
    <li class="pl-0 pb-4 md:pb-2 md:ml-8">
      If you have any queries or if you believe any information on this website
      and the replica of its content on my profiles on other websites, including
      but not limited to the content on
      <a class="link" href=" https://medium.com/@pooyan_razian/" target="_blank"
        >medium.com</a
      >, is inaccurate, or if you think any of the assets used on this website
      are in violation of copyright, please
      <router-link class="link" to="/contact">contact me</router-link>
      and let me know.
    </li>
  </ul>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SkillBarItem from "@/components/SkillBarItem.vue"; // @ is an alias to /src
import HobbyProjectItem from "@/components/HobbyProjectItem.vue"; // @ is an alias to /src
import Recommendation from "@/components/Recommendation.vue"; // @ is an alias to /src
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, faCloud } from "@fortawesome/free-solid-svg-icons";

library.add(faHeart, faCloud);

@Options({
  components: {
    SkillBarItem,
    Recommendation,
    HobbyProjectItem,
  },
})
export default class Certificates extends Vue {
  projects = [
    {
      name: "MindNations",
      url: "https://mindnations.com",
      image: "/img/projects/mind-nations.png",
    },
    {
      name: "M2Text",
      url: "https://m2text.com",
      image: "/img/projects/m2text.png",
    },
    {
      name: "Write Assistant",
      url: "https://chrome.google.com/webstore/detail/fjecechgnepfeonincjkmikllpjdjbgm/",
      image: "/img/projects/write-assistant-google-chrome-extension.png",
      isDeprecated: true,
    },
    {
      name: "Speak M8!",
      url: "https://speakm8.com",
      image: "/img/projects/speak-m8.svg",
      isDeprecated: true,
    },
    {
      name: "Digi Wolk",
      url: "https://digiwolk.com",
      image: "/img/projects/digi-wolk.png",
      isDeprecated: true,
    },
    {
      name: "Cloud Smart Bot",
      url: "https://twitter.com/cloud_smart_bot",
      image: "/img/bots/twitter_bot_cloud.jpg",
      isDeprecated: true,
    },
    {
      name: "Python Smart Bot",
      url: "https://twitter.com/py_smart_bot",
      image: "/img/bots/twitter_bot_python.jpg",
      isDeprecated: true,
    },
    {
      name: "PHP Smart Bot",
      url: "https://twitter.com/php_smart_bot",
      image: "/img/bots/twitter_bot_php.jpg",
      isDeprecated: true,
    },
    {
      name: "Golang Smart Bot",
      url: "https://twitter.com/go_smart_bot",
      image: "/img/bots/twitter_bot_golang.jpg",
      isDeprecated: true,
    },
    {
      name: "OlAudit",
      url: "https://github.com/digi-wolk/oss-license-auditor",
      image: "/img/projects/oss-license-auditor.svg",
      isDeprecated: true,
    },
    {
      name: "RSS Feed Reader",
      url: "https://github.com/digi-wolk/rss-feed-reader",
      image: "/img/projects/rss-feed-reader.png",
      isDeprecated: false,
    },
  ];

  recommendations = [
    {
      id: 1,
      authorFirstName: "Henrik",
      authorFamilyName: "Lernmark",
      authorJobTitle: "Head AWS Cloud, Security Officer & Site Manager",
      authorCompany: "adesso SE",
      url: "https://www.linkedin.com/in/henriklernmark/",
      content:
        "I had the pleasure to have Pooyan in my AWS cloud team at Adesso and he proved to be very knowledgable and capable in many areas of IT in general and AWS cloud and containers in particular. He is excellent at documenting his work and communicating with customers as well as with other team members.",
    },
    {
      id: 1,
      authorFirstName: "Tomz",
      authorFamilyName: "Pholakanit",
      authorJobTitle: "Cloud Specialist",
      authorCompany: "adesso SE",
      url: "https://www.linkedin.com/in/dsignlife/",
      content:
        "I've worked with Pooyan on multiple AWS projects from modernizing legacy projects, orchestration, CI/CD process, branching/release strategies, test automation, security and monitoring. Together we added values to many businesses. He is very bright, loves knowledge sharing, a real DevSecOps guy and a pleasure to work with. Any project would be more productive and fun with him in it! ☁️",
    },
    {
      id: 2,
      authorFirstName: "Daniel",
      authorFamilyName: "K.",
      authorJobTitle: "Machine Learning Engineer",
      authorCompany: "EasyTranslate",
      url: "https://www.linkedin.com/in/daniel-kanyik-b04a8378/",
      content:
        "I learnt a lot from Pooyan when it comes to setting up and working on development projects. He is a very knowledgeable engineer with great insight to technical details. He always approached the tasks with an open mind and put aside personal biases. His recommendations of using certain best practises for our AI system integration project were especially helpful and contributed significantly to our progress. I would work with him again without any hesitation.",
    },
    {
      id: 3,
      authorFirstName: "Anita",
      authorFamilyName: "T.",
      authorJobTitle: "Global Head of Sales",
      authorCompany: "EasyTranslate",
      url: "https://www.linkedin.com/in/anitatheis/",
      content:
        "As tech lead at EasyTranslate, Pooyan has mastered the art of bridging tech and people. It goes without saying that he is extremely acknowledgeable in his field. As tech lead, however, being a tech genius is not sufficient, as you need to be able to lead with influence and to create a common vision with the team and aligned with the company and all stakeholders involved. While working with Pooyan, I was constantly amazed by the empathy he shows and by his skills in listening and understanding customers, users, and processes alike in order to achieve a common vision that came to life.",
    },
  ];

  languages = [
    {
      name: "Persian (native)",
      experience: 5,
      type: "",
    },
    {
      name: "English (fluent)",
      experience: 4,
      type: "",
    },
    {
      name: "Danish (basic)",
      experience: 1,
      type: "",
    },
    {
      name: "German (basic)",
      experience: 1,
      type: "",
    },
    {
      name: "Arabic (basic)",
      experience: 1,
      type: "",
    },
  ];

  skills = [
    {
      name: "IT solution arch.",
      experience: 4,
      type: "topic",
    },
    {
      name: "Cloud infra.",
      experience: 5,
      type: "topic",
    },
    {
      name: "Backend dev.",
      experience: 5,
      type: "topic",
    },
    {
      name: "Frontend dev.",
      experience: 4,
      type: "topic",
    },
    {
      name: "Scrum",
      experience: 4,
      type: "topic",
    },
    {
      name: "DevOps",
      experience: 4,
      type: "topic",
    },
    {
      name: "IT security",
      experience: 3,
      type: "topic",
    },
    {
      name: "IT compliance",
      experience: 3,
      type: "topic",
    },
    {
      name: "Automation",
      experience: 4,
      type: "topic",
    },
    {
      name: "MarTech",
      experience: 4,
      type: "topic",
    },
    {
      name: "FinTech",
      experience: 2,
      type: "topic",
    },
    {
      name: "Telecom VAS",
      experience: 2,
      type: "topic",
    },
    {
      name: "Edge computing",
      experience: 1,
      type: "topic",
    },
    {
      name: "AWS cloud",
      experience: 5,
      type: "infra",
    },
    {
      name: "GCP cloud",
      experience: 2,
      type: "infra",
    },
    {
      name: "Azure cloud",
      experience: 2,
      type: "infra",
    },
    {
      name: "Linux",
      experience: 5,
      type: "infra",
    },
    {
      name: "Virtualization",
      experience: 4,
      type: "infra",
    },
    {
      name: "Containerization",
      experience: 4,
      type: "infra",
    },
    {
      name: "Service mesh",
      experience: 4,
      type: "infra",
    },
    {
      name: "Network",
      experience: 4,
      type: "infra",
    },
    {
      name: "Filesystems",
      experience: 4,
      type: "infra",
    },
    {
      name: "Shell scripting",
      experience: 5,
      type: "other",
    },
    {
      name: "Bash",
      experience: 5,
      type: "other",
    },
    {
      name: "CloudFormation",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS CDK",
      experience: 5,
      type: "infra",
    },
    {
      name: "Pulumi",
      experience: 4,
      type: "infra",
    },
    {
      name: "Terraform",
      experience: 3,
      type: "infra",
    },
    {
      name: "Docker",
      experience: 5,
      type: "infra",
    },
    {
      name: "kubernetes",
      experience: 3,
      type: "infra",
    },
    {
      name: "Amazon ECS",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS App Mesh",
      experience: 3,
      type: "infra",
    },
    {
      name: "AWS Cloud Map",
      experience: 4,
      type: "infra",
    },
    {
      name: "Amazon EC2",
      experience: 5,
      type: "infra",
    },
    {
      name: "AWS Lambda",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS Backup",
      experience: 5,
      type: "infra",
    },
    {
      name: "AWS SES",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS SSM",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS SNS",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS SQS",
      experience: 4,
      type: "infra",
    },
    {
      name: "EC2 ImageBuilder",
      experience: 4,
      type: "infra",
    },
    {
      name: "GuardDuty",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS Org.",
      experience: 4,
      type: "infra",
    },
    {
      name: "Control Tower",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS SSO",
      experience: 4,
      type: "infra",
    },
    {
      name: "Patch Manager",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS API GW",
      experience: 3,
      type: "infra",
    },
    {
      name: "CloudWatch",
      experience: 5,
      type: "infra",
    },
    {
      name: "Amazon Athena",
      experience: 4,
      type: "infra",
    },
    {
      name: "AWS ACM",
      experience: 5,
      type: "infra",
    },
    {
      name: "CloudFront",
      experience: 5,
      type: "infra",
    },
    {
      name: "S3",
      experience: 5,
      type: "infra",
    },
    {
      name: "IAM",
      experience: 5,
      type: "infra",
    },
    {
      name: "DNS",
      experience: 4,
      type: "infra",
    },
    {
      name: "CI/CD pipelines",
      experience: 5,
      type: "infra",
    },
    {
      name: "Github Actions",
      experience: 5,
      type: "infra",
    },
    {
      name: "Circle CI",
      experience: 4,
      type: "infra",
    },
    {
      name: "Gitlab CI",
      experience: 4,
      type: "infra",
    },
    {
      name: "VMWare",
      experience: 3,
      type: "infra",
    },
    {
      name: "Envoy",
      experience: 3,
      type: "infra",
    },
    {
      name: "Istio",
      experience: 3,
      type: "infra",
    },
    {
      name: "Prometheus",
      experience: 3,
      type: "infra",
    },
    {
      name: "GoLang",
      experience: 5,
      type: "be",
    },
    {
      name: "Python",
      experience: 5,
      type: "be",
    },
    {
      name: "JAVA",
      experience: 2,
      type: "be",
    },
    {
      name: "PHP",
      experience: 5,
      type: "be",
    },
    {
      name: "Gin (Go)",
      experience: 5,
      type: "be",
    },
    {
      name: "Mux (Go)",
      experience: 5,
      type: "be",
    },
    {
      name: "ExpressJs",
      experience: 5,
      type: "be",
    },
    {
      name: "Laravel",
      experience: 5,
      type: "be",
    },
    {
      name: "Symfony",
      experience: 3,
      type: "be",
    },
    {
      name: "Pimcore",
      experience: 2,
      type: "be",
    },
    {
      name: "gRPC",
      experience: 2,
      type: "be",
    },
    {
      name: "REST API",
      experience: 5,
      type: "be",
    },
    {
      name: "SOAP API",
      experience: 1,
      type: "be",
    },
    {
      name: "GraphQL",
      experience: 1,
      type: "be",
    },
    {
      name: "JavaScript",
      experience: 5,
      type: "fe",
    },
    {
      name: "TypeScript",
      experience: 5,
      type: "fe",
    },
    {
      name: "HTML",
      experience: 5,
      type: "fe",
    },
    {
      name: "CSS",
      experience: 4,
      type: "fe",
    },
    {
      name: "TailwindCSS",
      experience: 5,
      type: "fe",
    },
    {
      name: "Bootstrap CSS",
      experience: 3,
      type: "fe",
    },
    {
      name: "VueJs",
      experience: 5,
      type: "fe",
    },
    {
      name: "NuxtJs",
      experience: 5,
      type: "fe",
    },
    {
      name: "jQuery",
      experience: 4,
      type: "fe",
    },
    {
      name: "Angular",
      experience: 2,
      type: "fe",
    },
    {
      name: "DynamoDB",
      experience: 4,
      type: "db",
    },
    {
      name: "MySQL",
      experience: 4,
      type: "db",
    },
    {
      name: "PostgreSQL",
      experience: 3,
      type: "db",
    },
    {
      name: "OracleDB",
      experience: 2,
      type: "db",
    },
    {
      name: "MongoDB",
      experience: 3,
      type: "db",
    },
    {
      name: "Elastic stack",
      experience: 3,
      type: "db",
    },
    {
      name: "Redis",
      experience: 3,
      type: "db",
    },
    {
      name: "SQL",
      experience: 4,
      type: "db",
    },
    {
      name: "OWASP",
      experience: 3,
      type: "security",
    },
    {
      name: "TDD / BDD",
      experience: 3,
      type: "test",
    },
    {
      name: "JEST",
      experience: 3,
      type: "test",
    },
    {
      name: "PHPUnit",
      experience: 3,
      type: "test",
    },
    {
      name: "NodeJs",
      experience: 3,
      type: "other",
    },
    {
      name: "Git",
      experience: 5,
      type: "other",
    },
    {
      name: "OOP",
      experience: 5,
      type: "other",
    },
    {
      name: "SOA",
      experience: 4,
      type: "other",
    },
    {
      name: "Microservice arch",
      experience: 4,
      type: "other",
    },
    {
      name: "Event-driven arch",
      experience: 4,
      type: "other",
    },
    {
      name: "Design patterns",
      experience: 4,
      type: "other",
    },
    {
      name: "DDD",
      experience: 3,
      type: "other",
    },
    {
      name: "ITIL",
      experience: 3,
      type: "other",
    },
    {
      name: "ISO-27001",
      experience: 3,
      type: "other",
    },
    {
      name: "SEO",
      experience: 4,
      type: "other",
    },
    {
      name: "Adobe ACC",
      experience: 3,
      type: "other",
    },
    {
      name: "Adobe ACS",
      experience: 1,
      type: "other",
    },
    {
      name: "Adobe PS",
      experience: 2,
      type: "other",
    },
  ];

  talks = [
    {
      title: "Infrastructure as Software - From TypeScript to the Cloud",
      event: "CopenhagenJS meetup",
      month: "November",
      year: "2024",
      url: "https://www.meetup.com/copenhagenjs/events/304009244/",
      image: "/img/talks/infrastructure-as-software-from-typescript-to-the-cloud-cph-js-2024.png",
    },
    {
      title: "To test or not to test, \"How\" is the question!",
      event: "Code Club Copenhagen (3C) Meetup",
      month: "September",
      year: "2024",
      url: "https://www.youtube.com/watch?v=TSeA8fuxYUk",
      image: "/img/talks/to-test-or-not-to-test-how-is-the-question-3c-cph-2024.png",
    },
    {
      title: "Data Platforms on AWS: Lessons Learned",
      event: "Copenhagen AWS User Group Meetup",
      month: "June",
      year: "2024",
      url: "https://www.youtube.com/watch?v=qz0LzM6odos",
      image: "/img/talks/data-platforms-on-AWS-lessons-learned-aws-user-group-cph-2024.png",
    },
  ]
}


</script>
